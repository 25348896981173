.footer{
    background-color: var(--blue);
    padding: 2rem;
}

.footer-item .logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 2rem;
}

.footer .logo img{
    height: 4rem;
    width: 5rem;
    object-fit: cover;
}

.footer-item .logo h2{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white);
    letter-spacing: 0.2rem;
    padding-left: 0.5rem;
    padding-bottom: 0;
}

.footer .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 3rem;
    padding-bottom: 1rem;
} 

.footer-item h2{
    color: var(--white);
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding-bottom: 2rem;
}

.footer-item > p{
    font-size: 1.6rem;
    color: var(--white);
    line-height: 1.6;
    padding-bottom: 1rem;
}

.footer-item .info p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.footer-item .info .icon{
    color: var(--orange);
}

.footer-item .info a,
.footer-item .info p{
    font-size: 1.6rem;
    color: var(--white);
}

.footer-item .links p:hover{
    cursor: pointer;
}

.footer-item .links p:hover a{
    padding-left: 1.5rem;
}

.footer-item .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
}

.footer-item .social a{
    display: inline-block;
    height: 4rem;
    width: 4rem;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-item .social a .icon{
    color: var(--orange);
    font-size: 2rem;
}

.footer-item .social a:hover{
    background-color: var(--orange);
}

.footer-item .social a:hover .icon{
    color: var(--white);
}

.footer-item .gmail{
    text-transform: none;
}

.footer .content{
    text-align: center;
    padding: 2rem 0;
    border-top: 0.2rem solid var(--white);
}

.footer .content p{
    font-size: 2rem;
    color: var(--white);
}

.footer .content p span{
    color: var(--orange);
}

