.testimonials{
    overflow: hidden;
}  

.testimonial-slider{
    overflow: hidden;
    padding: 0rem 15rem;
    position: relative;
}

.testimonial-slider .swiper{
    overflow: visible !important;
    padding-bottom: 4rem; 
}


.testimonial-slider .swiper-button-next,
.testimonial-slider .swiper-button-prev{
    background-color: var(--orange);
    color: var(--white);
    font-size: 1rem;
    height: 5rem;
    width: 5rem;
    border-radius: var(--border-radius);
    padding: 2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.testimonial-slider .swiper-button-next{
    right: -10rem;
}

.testimonial-slider .swiper-button-prev{
    left: -10rem;
}

.testimonial-slider .swiper-button-next::after,
.testimonial-slider .swiper-button-prev::after {
    font-size: 2rem;
    font-weight: bold;
    color: var(--white); 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonial-slider .swiper-button-next:hover,
.testimonial-slider .swiper-button-prev:hover {
    color: var(--white);
    background-color: var(--blue);
}

.testimonials .swiper-pagination-bullet{
    height: 1rem;
    width: 4rem;
    border-radius: 50rem;
    background: var(--orange);
    opacity: 0.7;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.testimonials .swiper-pagination-bullet-active{
    width: 4rem;
    background: var(--blue);
    opacity: 0.9;
}

.testi-item{
    text-align: center;
    border-top-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    background-color: var(--white);
    overflow: hidden;
}

.testi-item .content{
    background-color: var(--blue);
    padding: 4rem 2rem 6rem 2rem;
}

.testi-item .comment{
    padding-top: 1rem;
    padding-bottom: 3rem;
    position: relative;
}

.testi-item .quote-start,
.testi-item .quote-end{
    position: absolute;
    font-size: 2.5rem;
    color: var(--white);
}

.testi-item .comment .quote-start{
    top: 0;
    left: 0;
}

.testi-item .comment .quote-end{
    right: 0;
    bottom: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.testi-item .comment p{
    color: var(--white);
    font-size: 1.8rem;
    line-height: 1.5;
    margin-top: 2rem;
}

.testi-item .intro{
    position: relative;
    padding: 8rem 2rem 4rem 2rem;
}

.testi-item .intro img{
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 0;
    left: 50%;
    height: 12rem;
    width: 12rem;
}

.testi-item .intro .rating .star{
    color: var(--orange);
    font-size: 2rem;
}

.testi-item .intro h3{
    font-size: 2rem;
    color: var(--blue);
    padding: 0.5rem 0;
}

.testi-item .intro h5{
    color: var(--grey);
    font-size: 1.4rem;
    font-weight: 400;
}

.testimonial-slider .swiper-horizontal>.swiper-pagination-bullets, 
.testimonial-slider .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: -3rem;
}