.controls .button{
    display: inline-block;
    pointer-events: auto;
	cursor: pointer;
	border: none;
	padding: 1.5rem 3rem;
	margin: 0; 
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-size: 1.6rem; 
	border-radius: 0.5rem;
	background: var(--orange); 
    color: var(--white);
	-webkit-box-shadow: var(--box-shadow);
	box-shadow: var(--box-shadow);
}

.controls .button:hover,
.controls .button.active{
    cursor: pointer;
	background: var(--blue);
}

._3bkri {
    background-color: var(--orange) !important;
}

._20cg_ {
	background-color: var(--orange) !important;
}

._20cg_:hover {
	background-color: var(--white) !important;
}

._20cg_:focus {
	-webkit-box-shadow: 0px 0px 0px 3px var(--blue) !important;
			box-shadow: 0px 0px 0px 3px var(--blue) !important;
}
