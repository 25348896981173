.heading{
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.heading h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 40px; 
  color: var(--blue);
  text-transform: capitalize;
  text-align: center;
  padding-bottom: 5px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.heading h1 span {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.4);
}

.heading h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: var(--orange);
}

.heading h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 1rem;
  left: 50%;
  margin-left: -50px;
  background-color: var(--orange);
}
