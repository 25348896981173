.home{
  padding: 0;
  height: 1px;
  min-height: 100vh;
  position: relative;
} 

.home-slider{
  position: relative;
} 

.home-slider .swiper-button-next,
.home-slider .swiper-button-prev{
  background-color: var(--orange);
  color: var(--white);
  font-size: 1rem;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  padding: 2rem;
}

.home-slider .swiper-button-next::after,
.home-slider .swiper-button-prev::after {
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center; 
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.home-slider .swiper-button-next:hover,
.home-slider .swiper-button-prev:hover {
  background-color: var(--blue);
}

.home-slider .swiper-pagination-bullet{
  height: 1rem;
  width: 4rem;
  border-radius: 50rem;
  background: var(--white);
  opacity: 0.5;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.home-slider .swiper-pagination-bullet-active{
  background: var(--orange);
  opacity: 0.9;
}

.home-item{
  min-height: 100vh;
}

.home-item img{
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.home-item .content{ 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
  background:         linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
}

.home-item .content .text{
  text-align: center;
  width: 70rem;
}

.home-item .content h3{
  font-size: 4.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: var(--white);
}

.home-item .content p{
  font-size: 1.7rem;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: var(--white);
  padding-bottom: 2rem;
  line-height: 1.6;
}

.home-item .content .btn-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1rem;
}


.home-item .content .btn-container .btn:nth-child(1)::before {
  background: var(--orange);
}

.home-item .content .btn-container .btn:nth-child(1)::after {
  border: 1px solid var(--orange);
}

  