.projects .box-container{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1.5rem;
    padding: 2rem 0; 
}

.controls{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    gap: 1rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 2rem 0rem;
}

