.counting{
    width: 100%; 
    padding: 2rem 5%;
    margin: 1rem 0;
    min-height: 15rem;
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 3rem;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../assets/Counter/Counter.png");
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../assets/Counter/Counter.png");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.counting .box{
    -webkit-box-flex: 1;
    -ms-flex:1 1 25rem;
        flex:1 1 25rem;
    text-align: center;
    padding: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.counting .box .icon{
    color: var(--orange);
    font-size: 6rem;
    width: 6rem;
    padding-bottom: 1rem;
    text-align: center;
}

.counting .box span{
    font-size: 5rem;
    font-weight: 500;
    color: var(--white);
    padding-bottom: 0.5rem;
}

.counting .box h3{
    font-size: 2rem;
    font-weight: 400;
    color: var(--white);
}
