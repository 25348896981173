.blog .box-container{
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 1.5rem;
}

.blog-item{ 
  background-color: var(--white);
  overflow: hidden;
  border-radius: var(--border-radius);
}

.blog-item:hover{
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
}

.blog-item .image{
  height: 30rem;
  border-radius: var(--border-radius);
  overflow: hidden;
} 

.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-item .content{
  padding: 2rem 1rem;
}

.blog-item .content .date{
  color: var(--grey);
  font-size: 1.7rem;
  padding: 0.5rem 0;
}

.blog-item .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--blue);
}

.blog-item .content .main-heading:hover{
  color: var(--orange);
  cursor: pointer;
}

.blog-item .content p{
  padding-top: 1rem;
  font-size: 1.5rem;
  color: var(--grey);
  line-height: 1.5;
}