.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;  
    padding: 2rem 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    -webkit-transition: all 0.5s linear;
       -moz-transition: all 0.5s linear;
         -o-transition: all 0.5s linear;
            transition: all 0.5s linear;
    z-index: 999;
    background-color: var(--white);
}

.header.sticky{
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    padding: 1rem 5%;
}

.header .logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .logo img{
    height: 4rem;
    width: 5rem;
    object-fit: cover;
}

.header .logo h2{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--blue);
    letter-spacing: 0.2rem;
    padding-left: 0.5rem;
}

.header .navbar{
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header .navbar li a{
    font-size: 1.8rem;
    color: var(--blue);
    margin: 0 0.5rem;
    padding: 0.5rem;
    position: relative;
}

.header .navbar li.is-current a,
.header .navbar a:hover{
    color: var(--orange);
}

.header .menu-btn{
    font-size: 3rem;
    color: var(--blue); 
    cursor: pointer;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    display: none;
}

.header .menu-btn:hover{
    color: var(--orange);
}

@media (max-width: 991px){
    .header .menu-btn{
        display: block;
      } 
  
      .header .navbar{
        width: 30rem;
        position: absolute;
        right: 0; 
        top:100%;
        padding: 3rem 2rem;
        background: var(--white);
        border: 0.1rem solid var(--orange);
        -webkit-transition: 0.2s linear;
        -moz-transition: 0.2s linear;
        -o-transition: 0.2s linear;
        transition: 0.2s linear;
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        height: calc(100vh - 6rem);
        overflow-y: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 4rem;
        z-index: 1000;
        -webkit-box-shadow: var(--box-shadow);
        box-shadow: var(--box-shadow);
    }
  
    .header .navbar.active{
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    .header .navbar li{
        display: block;
    }
  
    .header .navbar li a{
        font-size: 1.8rem;
        color: var(--blue);
        margin: 0 1rem;
        padding: 1rem 1.5rem;
        position: relative;
    }
  
    .navbar::-webkit-scrollbar{
        width: 0.8rem;
    }
  
    .navbar::-webkit-scrollbar-thumb{
        border-radius: 1rem;
        background-color: var(--orange);
    }
  
    .navbar::-webkit-scrollbar-track{
        background: transparent;
    }
  
}