.scroll-top{
    position: fixed;
    bottom: 1rem;
    right:1rem;
    height: 5rem;
    width: 5rem;
    font-size: 2rem;
    font-weight: 600;
    background: var(--orange);
    color: var(--white);
    border-radius: 50%; 
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 900;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .scroll-top:hover{
    background: var(--dark-black);
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    cursor: pointer;
  }

  .scroll-top svg{
      fill: var(--white)
  }
