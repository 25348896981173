/*--------------------- 0- Global CSS (Start) ---------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
    --blue: #293462;
    --orange: #F24C4C;
    --dark-black: #1C1427;
    --light-black: #40394A; 
    --white: #fff;
    --light-white: #DFDFDE;
    --grey: #666;
    --border-radius: 1rem;
    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

*{
    font-family: 'Roboto', sans-serif;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-transform: capitalize;
    border: none;
    outline: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

*::selection{
    color: var(--white);
    background-color: var(--orange);
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

section{
  padding: 4rem 5%;
  margin-top: 0.1rem;
}

::-webkit-scrollbar{
  width: 0.8rem;
}

::-webkit-scrollbar-thumb{
  border-radius: 1rem;
  background-color: var(--orange);
}

::-webkit-scrollbar-track{
  background: var(--blue);
}

/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
  html{
      font-size: 55%;
  }
}

@media (max-width: 768px){

  section, header{
      padding: 2rem;
  }

  .testimonial-slider .swiper-button-next{
    right: -6rem;
  }

  .testimonial-slider .swiper-button-prev{
      left: -6rem;
  }

  .testimonial-slider{
    padding: 0rem 7rem;
  }

}

@media (max-width: 450px){
  html{
      font-size: 50%;
  }

  section, header{
      padding: 1rem;
  }



}

/*--------------------- Responsive (End) ---------------------*/
