.pricing .box-container{
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(33rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 2rem;
}

.price-item{
  -webkit-box-flex: 1;
  -ms-flex:1 1 33rem; 
      flex:1 1 33rem;
  background-color: var(--white);
  text-align: center;
  padding-bottom: 2rem;
  border-radius: var(--border-radius);
  overflow: hidden;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
}

.price-item .intro{
  background-color: var(--blue);
  padding: 2rem 0;
}

  .price-item .price{
    color:var(--white);
    font-size: 5rem;
    font-weight: 400;
  }
  
.price-item .price span{
    font-size: 2rem;
    font-weight: normal;
  }
  
.price-item h3{
    color: var(--white);
    font-size: 3rem;
    font-weight: 400;
    padding:1.5rem 0;
  }
  
.price-item ul{ 
    list-style: none;
    padding:2rem 0;
  }
  
.price-item ul li{
    padding:.7rem 0;
    font-size: 1.7rem;
    color: var(--grey);
  }
  
.price-item .btn{
  margin-top: 2rem;
}

  