.contact{
    padding: 4rem 0;
}

.contact iframe{
    width: 100%;
    height: 30rem;
    margin-bottom: 1rem;
}

.contact h3{ 
    font-size: 3rem;
    font-weight: 500;
    padding-bottom: 1rem;
}

.contact > .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    margin: 0rem 5%;
}

.contact-info{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
    padding: 2rem;
    background: -webkit-linear-gradient(rgba(41, 52, 98, 0.8), rgba(41, 52, 98, 0.8)), url("../../assets/Contact/Contact-bg.png");
    background: linear-gradient(rgba(41, 52, 98, 0.8), rgba(41, 52, 98, 0.8)), url("../../assets/Contact/Contact-bg.png");
    background-position: center;
    background-size: cover;
}

.contact-info h3{
    color: var(--white);
}

.contact-info .info-item{
    padding: 1rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.contact-info .info-item .icon-item{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-info .info-item .icon{
    font-size: 1.6rem;
    color: var(--blue);
}

.contact-info .info-item .content{
    padding-left: 1rem;
}

.contact-info .info-item .content h4{
    font-size: 1.8rem;
    color: var(--orange);
}

.contact-info .info-item .content p{
    font-size: 1.6rem;
    color: var(--white);
    line-height: 1.6;
    text-transform: none;
}

.contact-form{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
    background-color: var(--white);
    border-radius: 1rem;
    padding: 2rem;
}

.contact-form h3{
    color: var(--dark-black);
}

.contact-form .box{
    width: 100%;
    text-transform: none;
    color: var(--grey);
    font-size: 1.6rem;
    border-bottom: 0.1rem solid var(--blue);
    padding: 1.5rem 0.5rem;
    margin: 0.5rem 0;
}

.contact-form .box:focus{
    border-color: var(--orange);
}

.contact-form .box::placeholder{
    color: var(--grey);
    text-transform: capitalize;
}

.contact-form textarea{
    height: 15rem;
    resize: none;
}

.contact-form button{
    margin-top: 1rem;
}

.contact .alert{
    font-size: 2rem;
    color: var(--orange);
    padding-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 1rem;
}

.contact .msg-alert{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}