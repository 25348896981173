.services .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.service-item{ 
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
        flex: 1 1 33rem;
    background-color: var(--white);
    padding: 4rem 3rem;
    text-align: center;
    border-radius: var(--border-radius);
}

.service-item:hover{
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
}

.service-item .icon{
    font-size: 4rem;
    color: var(--orange) !important;
}

.service-item h3{
    font-size: 2rem;
    color: var(--blue);
    padding: 1.5rem 0;
}

.service-item p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.6;
}
