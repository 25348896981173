.btn {
	pointer-events: auto;
	cursor: pointer;
	border: none;
	padding: 1.5rem 3rem;
	font-size: 1.8rem;
	font-weight: 500;
	font-style: italic; 
	position: relative;
	display: inline-block;
}

.btn::before,
.btn::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.btn::before {
	content: '';
	background: var(--blue);
	transition: transform 0.3s cubic-bezier(0.2,1,0.7,1);
}

.btn:hover::before {
	transform: scale3d(0.9, 0.8, 1);
}

.btn::after {
	content: '';
	border: 1px solid var(--blue);
	transition: transform 0.3s cubic-bezier(0.2,1,0.7,1);
	border-radius: 0px;
	transform: scale3d(0.85, 0.65, 1);
}

.btn:hover::after {
	transform: scale3d(1,1,1);
}

.btn span {
	display: inline-block;
	position: relative;
	color: var(--white);
}

